import React, { FC, useEffect, useState, useCallback } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getOperators } from "../../../../../types/subscriberConditionType";
import isWelcome from "../../../../../utils/welcomeSmsChecker";
import { TemplateEnum } from "../../../../../types/templateEnum";
import SelectFormItemComponent from "../../../../../components/form/items/select";
import InputFormItemComponent from "../../../../../components/form/items/input";
import { LabelValue } from "../../../../../types/labelValue";

type PreviousAnswerConditionComponentProps = {
  updateWithPreviousAnswer: (value: boolean) => void;
};

const PreviousAnswerConditionComponent: FC<PreviousAnswerConditionComponentProps> = ({ updateWithPreviousAnswer }) => {
  const { current: question, list: questions } = useSelector((state: RootState) => state.questions);
  const [isMultiple, setMultiple] = useState(false);
  const [prevAnswerChoices, setPrevAnswerChoices] = useState<Array<{ text: string; id: string }>>([]);
  const [operators, setOperators] = useState<Array<LabelValue>>([]);

  useEffect(() => {
    const answeredQuestionId = question?.previousAnswerCondition?.answeredQuestionId;
    if (answeredQuestionId) {
      const template = questions.find((q) => q.questionId === answeredQuestionId)?.answerTemplate;
      if (template) {
        setOperators(getOperators(template.type));
      }
    }
  }, [question, questions]);

  useEffect(() => {
    const multiple = checkIsMultiple(question?.previousAnswerCondition?.operator);
    setMultiple(multiple);

    if (multiple && question?.previousAnswerCondition?.answeredQuestionId) {
      loadChoices(question.previousAnswerCondition.answeredQuestionId);
    }
  }, [question?.previousAnswerCondition]);

  const checkIsMultiple = useCallback((type?: string) => {
    return type === "ONE_OF" || type === "NOT_ONE_OF";
  }, []);

  const loadChoices = useCallback(
    (answeredQuestionId?: string) => {
      const template = questions.find(
        (q) => q.questionId === (answeredQuestionId || question?.previousAnswerCondition?.answeredQuestionId),
      )?.answerTemplate;

      if (template?.type === TemplateEnum.SINGLE_CHOICE || template?.type === TemplateEnum.MULTIPLE_CHOICE) {
        const newChoices: Array<{ text: string; id: string }> = [];

        template?.answerChoices.forEach((choice) => {
          const firstTranslation = choice?.textTranslations?.[0];
          if (firstTranslation?.text && choice?.answerChoiceId) {
            newChoices.push({ text: firstTranslation.text, id: choice.answerChoiceId.toString() });
          }
        });

        const uniqueChoices = Array.from(new Map(newChoices.map((choice) => [choice.id, choice])).values());

        setPrevAnswerChoices(uniqueChoices);
        updateWithPreviousAnswer(true);
      } else {
        updateWithPreviousAnswer(false);
        setPrevAnswerChoices([]);
      }
    },
    [question, questions, setPrevAnswerChoices, updateWithPreviousAnswer],
  );

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const isString = (value: any): value is string => typeof value === "string";

  return (
    <div style={{ display: "flex", width: "100%", gap: 10 }}>
      <div style={{ flex: "0 0 270px" }}>
        <Form.Item noStyle shouldUpdate>
          {({ setFieldsValue }) => {
            function resetValue(answeredQuestionId: string) {
              setFieldsValue({
                previousAnswerCondition: {
                  answeredQuestionId,
                  operator: undefined,
                  value: undefined,
                },
              });

              const template = questions.find((q) => q.questionId === answeredQuestionId)?.answerTemplate;
              if (template) {
                setOperators(getOperators(template.type));
              }
              if (checkIsMultiple(template?.type)) {
                loadChoices(answeredQuestionId);
              } else {
                setPrevAnswerChoices([]);
                updateWithPreviousAnswer(true);
              }
            }

            return (
              <SelectFormItemComponent
                noStyle
                label={""}
                required={false}
                hasFeedback={false}
                onChange={resetValue}
                style={{ width: "100%" }}
                name={["previousAnswerCondition", "answeredQuestionId"]}
                values={questions
                  .filter((q) => q.questionId !== question?.questionId && !isWelcome(q))
                  .map((q) => ({
                    value: q.questionId || "",
                    label: q.name,
                  }))}
              />
            );
          }}
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 10, flex: 1 }}>
        <Form.Item noStyle shouldUpdate>
          {({ setFieldsValue, getFieldValue }) => {
            function resetOperatorValue(e: string) {
              const _isOneOf = checkIsMultiple(e);
              setMultiple(_isOneOf);
              if (_isOneOf) {
                const answeredQuestionId = getFieldValue(["previousAnswerCondition", "answeredQuestionId"]);
                loadChoices(answeredQuestionId);
              } else {
                updateWithPreviousAnswer(true);
              }
              setFieldsValue({
                previousAnswerCondition: {
                  operator: e,
                  value: undefined,
                },
              });
            }
            const isRequired = !!getFieldValue(["previousAnswerCondition", "answeredQuestionId"]);

            return (
              <SelectFormItemComponent
                noStyle
                label={""}
                required={isRequired}
                values={operators}
                hasFeedback={false}
                style={{ width: "160px" }}
                onChange={resetOperatorValue}
                name={["previousAnswerCondition", "operator"]}
              />
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const operatorIsNotEmpty = !!getFieldValue(["previousAnswerCondition", "operator"]);

            return isMultiple ? (
              <SelectFormItemComponent
                required={operatorIsNotEmpty}
                label={""}
                maxTagCount={1}
                mode={"multiple"}
                style={{ width: 300, height: 40 }}
                hasFeedback={false}
                name={["previousAnswerCondition", "value"]}
                values={prevAnswerChoices.map((it) => ({
                  label: isString(it.text) ? truncateText(it.text, 15) : it.text,
                  value: it.id,
                }))}
              />
            ) : (
              <InputFormItemComponent
                name={["previousAnswerCondition", "value"]}
                required={operatorIsNotEmpty}
                label={""}
                style={{ width: 300, height: 40 }}
                onChange={() => updateWithPreviousAnswer(true)}
              />
            );
          }}
        </Form.Item>
      </div>
    </div>
  );
};

export default PreviousAnswerConditionComponent;
